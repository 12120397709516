import React from "react"
import Layout from "../components/Layout";
import Section from '../components/Section';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Column, colors, fonts, mediaQueries } from '../styles';
import BlogFeed from "../components/BlogFeed";
import Arrow from "../images/arrowright.inline.svg";

const Hero = styled.div`
    position: relative;
    padding-bottom: 35%;
    background-color: ${colors.dark};

    .gatsby-image-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: .7
    }

`

const HeroContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    z-index: 1;
    color: #ffffff;
    padding: 0px 20px max(5%, 20px) 20px;
`

// markup
const Newsroom = () => {

  const data = useStaticQuery(graphql`
    {
      allNewsJson(sort: {fields: date, order: DESC}) {
        edges {
          node {
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.25)
              }
            }
            date(formatString: "MM.DD.YY")
            link
            title
          }
        }
      }
    }
  `)

    const blog = data.allNewsJson.edges;
    const mainBlog = blog.slice(1);
    const firstBlog = blog[0];

    return (
        <Layout>
            <main>
                <Hero>
                    <HeroContent>
                        <Container fluid style={{ width: '100%' }}>
                            <h3 style={{ marginBottom: 20 }}>{firstBlog.node.title}</h3>
                            <h5 style={{ marginBottom: 20 }}>{firstBlog.node.date}</h5>
                            <a href={firstBlog.node.link} style={{ fontSize: '.889rem', letterSpacing: '0.1875em', fontWeight: 'bold', lineHeight: 1 }} target='_blank'>
                            READ MORE <Arrow style={{width: 'auto', display: 'inline-block', height: 12}} />
                            </a>
                        </Container>
                    </HeroContent>
                    <GatsbyImage image={getImage(firstBlog.node.image)} alt={firstBlog.node.title} />
                </Hero>
                <Section>
                    <Container fluid style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Row alignY='stretch'>
                            <BlogFeed nodes={mainBlog} />
                        </Row>
                    </Container>
                </Section>
            </main>
        </Layout>
    )
}

  export default Newsroom