import React from 'react'
import { Row } from '../styles'
import BlogEntry from './BlogEntry'

const BlogFeed = ({ nodes }) => {
    return (
        <Row alignX="space-between" alignY="stretch">
            {nodes.map(({ node }, index) => (
                <BlogEntry node={node} md={33} key={index} />
            ))}
        </Row>
    )
}

export default BlogFeed
